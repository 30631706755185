import React from 'react';
import styled from 'styled-components';

const FourOhFour = () => {
  return (
    <div>
      <Wrap>
        <h1 style={{ color: '#fff' }}>Heyooo Sorry! Looks like you've hit a</h1>
        <h1 style={{ color: '#fff', fontSize: '4rem' }}>404!</h1>
      </Wrap>
    </div>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
`;

export default FourOhFour;
